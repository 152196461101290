import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Loading from '../Layout/Loading';
import Button from '@mui/material/Button';
import { UserContext } from '../../context';

const fetchCollection = async bgg_user => {
  const rs = await fetch(`${process.env.REACT_APP_COLLECTION_ENDPOINT}/player/collection/${encodeURI(bgg_user)}`, {
    method: "GET",
    headers: {
      "SA": localStorage.getItem("st"),
    }
  });

  return rs.json();
}

const Component = () => {
  const { state: { bgg_user }, dispatch } = React.useContext(UserContext);
  const [value, setValue] = React.useState(bgg_user);

  const { data, isLoading, status } = useQuery({
    queryKey: ["collection", value],
    queryFn: () => fetchCollection(value),
    enabled: value !== "",
  });

  React.useEffect(() => {
    if (status === "success") {
      dispatch({
        type: "collection::set",
        collection: data.data,
      });
    }
  }, [dispatch, data, status])

  if (isLoading) {
    return <Loading />;
  }

  const handleClick = async () => {
    setValue(bgg_user);
  }

  return (
    <Button variant="contained" disabled={isLoading} onClick={handleClick}>Fetch</Button>
  );
}

export default Component;
